
import './App.css';


import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Card, Modal } from '@mui/material';
import video from './images/vid.mp4';

const drawerWidth = 240;
const navItems = ['Invitation', 'Timeline', 'Location'];

export default function App(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleMenuItemClick = (item) => {
    if(item === 'Invitation') {
      setOpen(true);
    } else if (item === 'Location') {
      window.open('https://www.google.com/maps/place/Aashyana+Lakhanpal/@15.5261091,73.7599644,17z/data=!3m1!4b1!4m10!3m9!1s0x3bbfc1edb228d7cd:0x39c64c6e1b23be4f!5m3!1s2024-04-21!4m1!1i2!8m2!3d15.5261091!4d73.7625393!16s%2Fg%2F11gs4wtxwf?entry=ttu', '_blank')
    }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', bgcolor: 'transparent' }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleMenuItemClick(item)}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Box>
      <Box sx={{ display: 'flex' }} className="bg bg-tint">
      <CssBaseline />
      <AppBar component="nav" sx={{ bgcolor: "transparent", boxShadow: 'none' }} >
        <Toolbar className='appbar'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }} onClick={() => handleMenuItemClick(item)}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
      </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Card sx={{ maxWidth: 380, flexGrow: 1 }}>
              <video style={{ maxWidth: 385 }} controls autoplay>
                <source  src={video} type="video/mp4"/>
              </video>
            </Card>
          </div>
        </Modal>
    </Box> 
  );
}